import React from "react";
import { GetQuotePayload, useGetQuoteQuery } from "./quotes.api";
import QuotationForm from "./components/QuotationForm";
import Spinner from "../common/components/Spinner";
import QuotationResults from "./components/QuotationResults";

export default function QuotationPage() {
  const [formData, setFormData] = React.useState<GetQuotePayload | null>(null);
  const { data, isLoading } = useGetQuoteQuery(formData);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-20">
        <Spinner />
      </div>
    );
  }

  if (data && formData) {
    return (
      <QuotationResults
        formData={formData}
        quotationResults={data}
        onBack={() => setFormData(null)}
      />
    );
  }

  return <QuotationForm setFormData={setFormData} />;
}
