import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { capitalize } from "lodash";
import { ArrowDownIcon, ArrowUpIcon } from "../icons";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { cn } from "../utils";

const inputVariants = cva(
  [
    "w-full bg-transparent h-8 pb-[6px] px-4 focus:outline-none hover:border-gray-600 hover:border-b-2",
    { "number&": "appearance-none" },
  ],
  {
    variants: {
      intent: {
        default:
          "border-b-[1px] border-gray-400 focus:border-b-2 focus:border-primary ",
        error:
          "border-b-[1px] border-red-500 text-red-500 focus:border-b-2 focus:border-red-500",
        disabled: "bg-gray-200 cursor-not-allowed opacity-50",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  }
);

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  label: string;
  watch?: (name: keyof FieldValues) => any;
  setValue?: UseFormSetValue<any>;
  incremental?: boolean;
  errorMessage?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      errorMessage,
      intent = "default",
      incremental = false,
      setValue,
      watch,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("flex flex-col relative")}>
        <label className="text-sm font-semibold mb-2 leading-6 text-primary">
          {capitalize(label)}
        </label>
        <div className="relative">
          <input
            {...props}
            ref={ref}
            className={cn(inputVariants({ intent }), className)}
          />
          {props.type === "number" && incremental && (
            <div className="absolute inset-y-0 pb-4 pr-4 right-0 top-0 h-full flex flex-col justify-center">
              <button
                type="button"
                className="p-0 disabled:text-gray-500 text-primary"
                onClick={() => {
                  setValue &&
                    setValue(props?.name!, Number(watch!(props.name!)) + 1, {
                      shouldValidate: true,
                    });
                }}
                disabled={props.disabled}
              >
                <ArrowUpIcon />
              </button>
              <button
                type="button"
                className="p-0 disabled:text-gray-500 text-primary"
                onClick={() =>
                  setValue &&
                  setValue(props?.name!, Number(watch!(props.name!)) - 1, {
                    shouldValidate: true,
                  })
                }
                disabled={
                  props.disabled || Number(watch!(props.name!)) === props.min
                }
              >
                <ArrowDownIcon />
              </button>
            </div>
          )}
        </div>

        {errorMessage && (
          <p
            className={cn(
              "mt-1 text-xs transition-opacity duration-500 ease-in-out text-red-500"
            )}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);

export { Input };
