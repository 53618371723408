import { capitalize } from "lodash";
import { GetQuotePayload, GetQuoteResponse } from "../quotes.api";
import { employTypes, zoneTypes } from "./QuotationForm";
import { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import doctoredLogo from "../../common/assets/doctored.png";
import galenoLogo from "../../common/assets/galeno.png";
import omintLogo from "../../common/assets/omint.png";
import premedicLogo from "../../common/assets/premedic.png";
import prevencionLogo from "../../common/assets/prevencion.png";
import sancorLogo from "../../common/assets/sancor.png";
import reintegroIcon from "../../common/assets/reintegro-icono.png";
import internacionIcon from "../../common/assets/internacion-icono.png";
import coberturaIcon from "../../common/assets/cobertura-icono.png";
import domicilioIcon from "../../common/assets/domicilio-icono.png";
import ortodonciaIcon from "../../common/assets/ortodoncia-icono.png";
import consultasIcon from "../../common/assets/consultas-icono.png";
import implatesIcon from "../../common/assets/implante-icono.png";
import odontologiaIcon from "../../common/assets/odontologia-icono.png";
import oftalmologiaIcon from "../../common/assets/oftalmologia-icono.png";
import opticaIcon from "../../common/assets/optica-icono.png";
import guardiaIcon from "../../common/assets/guardia-icono.png";
import mentalIcon from "../../common/assets/mental-icono.png";
import cirugiaIcon from "../../common/assets/cirugia-icono.png";
import maternidadIcon from "../../common/assets/maternidad-icono.png";
import laboratorioIcon from "../../common/assets/laboratorio-icono.png";
import diagnosticoIcon from "../../common/assets/diagnostico-icono.png";
import pesoIcon from "../../common/assets/peso-icono.png";
import kinesiologiaIcon from "../../common/assets/kinesiologia-icono.png";
import farmaciaIcon from "../../common/assets/farmacia-icono.png";
import {
  BigArrowDown,
  BigArrowLeft,
  BigArrowRight,
  PinIcon,
  SearchIcon,
} from "../../common/icons";
import { cn } from "../../common/utils";
import { AnimatePresence, motion } from "framer-motion";
import {
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Button } from "../../common/components/Button";

interface QuotationResultsProps {
  formData: GetQuotePayload;
  quotationResults: GetQuoteResponse[];
  onBack: () => void;
}

interface CardProps {
  quote: GetQuoteResponse;
  order?: number;
  selectedPlans: GetQuoteResponse[] | undefined;
  setSelectedPlans: (selectedPlans: GetQuoteResponse[]) => void;
  isInHeader?: boolean;
  setOpenDetails?: () => void;
}

function getLogo(nameProvider: string) {
  const logos = {
    DOCTORED: doctoredLogo,
    GALENO: galenoLogo,
    OMINT: omintLogo,
    PREMEDIC: premedicLogo,
    PREVENCION: prevencionLogo,
    SANCOR: sancorLogo,
  };
  return logos[nameProvider as keyof typeof logos] || "";
}

function getOrderLabel(orderBy: "relevance" | "maxPrice" | "minPrice") {
  switch (orderBy) {
    case "relevance":
      return "Relevancia";
    case "maxPrice":
      return "Menor precio";
    case "minPrice":
      return "Mayor precio";
  }
}

const providersOptions = [
  { value: "doctored", label: "Doctored", image: doctoredLogo },
  { value: "galeno", label: "Galeno", image: galenoLogo },
  { value: "omint", label: "Omint", image: omintLogo },
  { value: "premedic", label: "Premedic", image: premedicLogo },
  { value: "prevencion", label: "Prevención", image: prevencionLogo },
  { value: "sancor", label: "Sancor", image: sancorLogo },
];

const benefitsOptions = [
  { value: "emergency_room", label: "Guardia" },
  {
    value: "hospitalization",
    label: "Internacion habitacion c o i",
  },
  { value: "dentistry", label: "Odontologia" },
  { value: "orthodontics", label: "Ortodoncia" },
  { value: "office_visit", label: "Atencion en consultorio" },
  { value: "prosthesis", label: "Protesis e implantes POR REINTEGRO" },
  { value: "PMI", label: "Plan materno infantil" },
  { value: "optics", label: "Opticas 100% hasta 15 años" },
  { value: "home_visits", label: "Consultas en Domicilio" },
  { value: "diagnostic_imaging", label: "Diagnostico por Imagen" },
  { value: "laboratory", label: "Laboratorio" },
  { value: "physical_therapy", label: "Kinesiologia" },
  { value: "psychology", label: "Psicologia" },
  { value: "ophthalmology", label: "Oftalmologia" },
  { value: "outpatient_medications", label: "Medicamentos ambulatorios" },
  { value: "cosmetic_surgery", label: "Cirugía Estetica" },
  { value: "bruxism_guard", label: "Placas bucales de bruxismo" },
  { value: "rpg", label: "Reeducación Postural Global" },
  { value: "telemedicine", label: "Telemedicina" },
];

function Filters({
  age,
  employType,
  zone,
  selectedProviders,
  setSelectedProviders,
  selectedBenefits,
  setSelectedBenefits,
  search,
  setSearch,
  maxPrice,
  setMaxPrice,
  orderBy,
  setOrderBy,
  goBack,
}: {
  age: number;
  employType: { value: number; label: string };
  zone: { value: number; label: string };
  selectedProviders: string[];
  setSelectedProviders: (selected: string[]) => void;
  selectedBenefits: string[];
  setSelectedBenefits: (selected: string[]) => void;
  search: string;
  setSearch: (search: string) => void;
  maxPrice?: number;
  setMaxPrice: (price: number | undefined) => void;
  orderBy: "relevance" | "maxPrice" | "minPrice";
  setOrderBy: (orderBy: "relevance" | "maxPrice" | "minPrice") => void;
  goBack: () => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getChipLabel = () => {
    if (selectedBenefits.length === benefitsOptions.length) {
      return (
        <p>
          <span className="font-semibold">Beneficios:</span>
          Todos
        </p>
      );
    }

    const selectedLabels = selectedBenefits
      .map((value) => benefitsOptions.find((option) => option.value === value))
      .map((option) => option?.label)
      .filter(Boolean);

    if (isMobile) {
      const count = selectedLabels.length;
      return (
        <p>
          <span className="font-semibold">Beneficios: </span>`${count}{" "}
          seleccionado${count !== 1 ? "s" : ""}`
        </p>
      );
    }

    if (selectedLabels.length > 4) {
      return (
        <p>
          <span className="font-semibold">Beneficios: </span>
          `${selectedLabels.slice(0, 4).join(", ")}...`
        </p>
      );
    }

    return (
      <p>
        <span className="font-semibold">Beneficios: </span>
        {selectedLabels.join(", ")}
      </p>
    );
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="px-6 md:px-20 grid grid-cols-1 md:grid-cols-8 gap-4 ">
        <FormControl>
          <Select
            multiple
            size="small"
            displayEmpty
            value={selectedProviders}
            onChange={(event: any) => {
              const {
                target: { value },
              } = event;
              setSelectedProviders(value);
            }}
            input={<OutlinedInput />}
            renderValue={() => <p>Prepagas</p>}
            inputProps={{ "aria-label": "Without label" }}
          >
            {providersOptions.map((provider) => (
              <MenuItem key={provider.value} value={provider.value}>
                <Checkbox
                  checked={selectedProviders.includes(provider.value)}
                />
                <ListItemIcon>
                  <img
                    src={provider.image}
                    alt={provider.label}
                    style={{ width: 30, height: 30, marginRight: 10 }}
                  />
                </ListItemIcon>
                <ListItemText primary={provider.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            multiple
            size="small"
            displayEmpty
            value={selectedBenefits}
            onChange={(event: any) => {
              const {
                target: { value },
              } = event;
              setSelectedBenefits(value);
            }}
            input={<OutlinedInput />}
            renderValue={() => <p>Beneficios</p>}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 350 } },
            }}
          >
            {benefitsOptions.map((benefit) => (
              <MenuItem key={benefit.value} value={benefit.value}>
                <Checkbox checked={selectedBenefits.includes(benefit.value)} />

                <ListItemText primary={benefit.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="col-span-2">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
              border: "1px solid #ccc",
              background: "transparent",
              width: "100%",
              height: 40,
            }}
          >
            <div className="px-2">
              <SearchIcon className="py-1" />
            </div>

            <InputBase
              placeholder="Buscar por clinicas"
              className="w-full"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Paper>
        </div>

        <div className="col-span-1">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
              border: "1px solid #ccc",
              background: "transparent",
              width: "100%",
              height: 40,
            }}
          >
            <div className="px-2">
              <img src={pesoIcon} alt="peso" className="w-6" />
            </div>

            <InputBase
              placeholder="Precio hasta"
              type="number"
              className="w-full"
              value={maxPrice ?? ""}
              onChange={(e) => setMaxPrice(parseInt(e.target.value))}
            />
          </Paper>
        </div>
        <div className="col-span-1">
          <Select
            size="small"
            value={orderBy}
            onChange={(event: any) => {
              const {
                target: { value },
              } = event;
              setOrderBy(value);
            }}
            input={<OutlinedInput />}
            renderValue={() => <p>Ordenar por</p>}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="relevance">
              <ListItemText primary={getOrderLabel("relevance")} />
            </MenuItem>
            <MenuItem value="maxPrice">
              <ListItemText primary={getOrderLabel("maxPrice")} />
            </MenuItem>
            <MenuItem value="minPrice">
              <ListItemText primary={getOrderLabel("minPrice")} />
            </MenuItem>
          </Select>
        </div>
        <div className="flex justify-end col-span-2">
          <Button size="xs" onClick={goBack}>
            Volver
          </Button>
        </div>
      </div>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="bg-purple-200 overflow-hidden"
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ delay: 0.1, duration: 0.3 }}
            className="py-4 px-6 md:px-20"
          >
            <div className="flex flex-wrap gap-2">
              <Chip label={`${age} años`} variant="outlined" />
              <Chip label={`${employType.label}`} variant="outlined" />
              <Chip label={`${zone.label}`} variant="outlined" />
              <Chip label={getOrderLabel(orderBy)} variant="outlined" />
              {selectedProviders.length > 0 && (
                <AnimatePresence>
                  <motion.div
                    key="selected"
                    layout
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.2 },
                      layout: { duration: 0.3 },
                    }}
                  >
                    <Chip
                      variant="outlined"
                      label={
                        selectedProviders.length === providersOptions.length ? (
                          <p>
                            <span className="font-semibold">Prepagas:</span>
                            Todas
                          </p>
                        ) : (
                          <p>
                            <span className="font-semibold">Prepagas:</span>
                            {` ${selectedProviders
                              .map((value) =>
                                providersOptions.find(
                                  (option) => option.value === value
                                )
                              )
                              .map((option) => option?.label)
                              .join(", ")}
                          `}
                          </p>
                        )
                      }
                      onDelete={() => setSelectedProviders([])}
                    />
                  </motion.div>
                </AnimatePresence>
              )}
              {selectedBenefits.length > 0 && (
                <AnimatePresence>
                  <motion.div
                    key="selectedBenefits"
                    layout
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.2 },
                      layout: { duration: 0.3 },
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        minHeight: 32,
                        maxWidth: "100%",
                        "& .MuiChip-label": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      variant="outlined"
                      label={getChipLabel()}
                      onDelete={() => setSelectedBenefits([])}
                    />
                  </motion.div>
                </AnimatePresence>
              )}

              {maxPrice && maxPrice.toString().length >= 5 && (
                <Chip
                  label={`Precio hasta $${maxPrice}`}
                  variant="outlined"
                  onDelete={() => setMaxPrice(undefined)}
                />
              )}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

function Card({
  quote,
  order,
  selectedPlans,
  setSelectedPlans,
  isInHeader = false,

  setOpenDetails,
}: CardProps) {
  const isSelected = selectedPlans?.some(
    (plan) => plan.planId === quote.planId
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSelection = () => {
    if (isSelected) {
      setSelectedPlans(
        selectedPlans?.filter((plan) => plan.planId !== quote.planId) || []
      );
    } else {
      setSelectedPlans([...(selectedPlans || []), quote]);
    }
  };

  return (
    <div
      className={cn(
        "shadow-sm bg-white rounded-2xl flex flex-col  ",
        isInHeader ? "md:min-h-[160px]" : "md:min-h-[436px] shadow-xl ",
        order! <= 3 && "border-primary border-4 "
      )}
    >
      {/*Recomended header*/}
      {order! <= 3 && (
        <div className="bg-primary h-10 flex justify-center items-center">
          <p className="font-semibold text-white ">Recomendado</p>
        </div>
      )}
      <div className="p-6 flex flex-col justify-between h-full">
        <div>
          {/* Header */}
          <div className="flex w-full justify-between items-center mb-4">
            <img
              src={getLogo(quote.nameprovider)}
              alt={quote.nameprovider}
              className="w-8 h-8"
            />
            <p className="font-bold leading-6 text-primary text-lg">
              {quote.nameplan}
            </p>
            <button
              onClick={toggleSelection}
              disabled={
                selectedPlans && selectedPlans.length >= 3 && !isSelected
              }
              className={cn(
                "hover:opacity-100 opacity-90 transition-opacity duration-150 ease-in-out",
                isSelected ? "text-primary" : "text-gray-300"
              )}
            >
              <PinIcon className="w-6 h-6" />
            </button>
          </div>
          <div className="space-y-2 text-lg flex flex-col gap-4">
            {/* Data */}
            <div className="flex justify-between">
              <p>Costo total</p>
              <p className="font-bold text-primary">${quote.price}</p>
            </div>
            {/* Benefits */}
            {(!isInHeader || isExpanded) && (
              <div className="mt-4 flex flex-col gap-2">
                <p className="text-primary font-semibold text-center">
                  Beneficios destacados
                </p>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-center gap-4">
                    <div className="flex items-center">
                      <img
                        src={reintegroIcon}
                        alt="Reintegro"
                        className="w-6 h-6 mr-2"
                      />
                      <p>{quote.copayment}</p>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={coberturaIcon}
                        alt="Cobertura"
                        className="w-6 h-6 mr-2"
                      />
                      <p>{quote.coverage}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <img
                      src={internacionIcon}
                      alt="Habitación"
                      className="w-6 h-6 mr-2"
                    />
                    <p>
                      {quote.hospitalization !== "ST - SL - SC"
                        ? capitalize(quote.hospitalization)
                        : quote.hospitalization}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Footer and actions */}
        {isInHeader ? (
          <div className="flex justify-center mt-4">
            <button onClick={() => setIsExpanded(!isExpanded)}>
              <BigArrowDown
                className={isExpanded ? "transform rotate-180" : ""}
              />
            </button>
          </div>
        ) : (
          <>
            <button
              className="w-full text-primary font-bold"
              onClick={setOpenDetails}
            >
              Quiero más detalles
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function QuoteDetails({ quote }: { quote: GetQuoteResponse }) {
  const [selectedZoneId, setSelectedZoneId] = useState("");

  const availableZones = useMemo(() => {
    if (!quote.clinics || quote.clinics.length === 0) return [];
    const zoneIds = Array.from(
      new Set(quote.clinics.map((clinic) => clinic.zoneId))
    );
    return zoneTypes.filter((zone) => zoneIds.includes(zone.value));
  }, [quote.clinics]);

  const filteredClinics = useMemo(() => {
    if (!quote.clinics) return [];
    return selectedZoneId
      ? quote.clinics.filter(
          (clinic) => clinic.zoneId === parseInt(selectedZoneId)
        )
      : quote.clinics;
  }, [selectedZoneId, quote.clinics]);

  const handleZoneChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedZoneId(event.target.value);
  };

  useEffect(() => {
    if (availableZones.length > 0 && !selectedZoneId) {
      setSelectedZoneId(availableZones[0].value.toString());
    }
  }, [availableZones, selectedZoneId]);

  return (
    <div className="px-2 md:px-6">
      <h2 className="text-2xl font-bold mb-4">{`${quote.nameprovider}/${quote.baseplan}`}</h2>

      {quote.clinics && quote.clinics.length > 0 && (
        <div className="mb-6 flex flex-col gap-4">
          <h3 className="text-lg font-semibold text-primary mb-2">
            Clínicas y Sanatorios
          </h3>

          <div className="flex flex-col">
            <label
              htmlFor="zone"
              className="font-semibold text-sm leading-6 text-primary"
            >
              Zona
            </label>
            <TextField
              id="zone"
              select
              value={selectedZoneId}
              hiddenLabel
              onChange={handleZoneChange}
              variant="standard"
            >
              {availableZones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="grid grid-cols-3 gap-6">
            {filteredClinics.map((clinic) => (
              <p
                className="text-sm font-semibold text-gray-600"
                key={clinic.clinicId}
              >
                {capitalize(clinic.clinicName.toLowerCase())}
              </p>
            ))}
          </div>
        </div>
      )}
      <h3 className="text-lg font-semibold text-primary mb-2">Beneficios</h3>

      <div className="grid grid-cols-2 gap-4">
        <>
          <div className="flex">
            <img
              src={internacionIcon}
              alt="Habitación"
              className="w-6 h-6 mr-2"
            />
            <span>Internación</span>
          </div>
          <div className="flex">
            <span>{quote.hospitalization.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={mentalIcon} alt="psicologia" className="w-6 h-6 mr-2" />
            <span>Psicologia</span>
          </div>
          <div className="flex">
            <span>{capitalize(quote.psychology)}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <p className="h-4">
              <img
                src={domicilioIcon}
                alt="Cobertura"
                className="w-6 h-6 mr-2"
              />
            </p>
            <span>Médico a domicilio </span>
          </div>
          <div className="flex">
            <span>{quote.home_visits}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={maternidadIcon}
              alt="Maternidad"
              className="w-6 h-6 mr-2"
            />
            <span>Maternidad</span>
          </div>
          <div className="flex">
            <span>{quote.PMI}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={farmaciaIcon} alt="Farmacia" className="w-6 h-6 mr-2" />
            <span>Farmacia</span>
          </div>
          <div className="flex">
            <span>{quote.outpatient_medications.toLowerCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={cirugiaIcon} alt="Cirugía" className="w-6 h-6 mr-2" />
            <span>Cirugía estética</span>
          </div>
          <div className="flex">
            <span>{quote.cosmetic_surgery}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={laboratorioIcon}
              alt="Laboratorio"
              className="w-6 h-6 mr-2"
            />
            <span>Laboratorio</span>
          </div>
          <div className="flex">
            <span>{quote.laboratory}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={diagnosticoIcon}
              alt="Diagnostico"
              className="w-6 h-6 mr-2"
            />
            <span>Diagnóstico por imagen</span>
          </div>
          <div className="flex">
            <span>{quote.diagnostic_imaging}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={kinesiologiaIcon}
              alt="Kinesiologia"
              className="w-6 h-6 mr-2"
            />
            <span>Kinesiologia</span>
          </div>
          <div className="flex">
            <span>{quote.physical_therapy}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <p className="h-4">
              <img src={guardiaIcon} alt="guardia" className="w-6 h-6 mr-2" />
            </p>
            <span>Guardia </span>
          </div>
          <div className="flex">
            <span>{quote.emergency_room}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={consultasIcon} alt="Consultas" className="w-6 h-6 mr-2" />
            <span>Consultas médicas </span>
          </div>
          <div className="flex">
            <p className="h-4"></p>
            <span>{quote.office_visit}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={ortodonciaIcon}
              alt="Ortodoncia"
              className="w-6 h-6 mr-2"
            />
            <span>Ortodoncia </span>
          </div>
          <div className="flex">
            <span>{quote.orthodontics}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={implatesIcon} alt="Implantes" className="w-6 h-6 mr-2" />
            <span>Implantes y prótesis dentales</span>
          </div>
          <div className="flex">
            <span>{quote.prosthesis.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={opticaIcon} alt="optica" className="w-6 h-6 mr-2" />
            <span>Óptica</span>
          </div>
          <div className="flex">
            <span>{quote.optics}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={oftalmologiaIcon}
              alt="oftalmologia"
              className="w-6 h-6 mr-2"
            />
            <span>Oftalmologia</span>
          </div>
          <div className="flex">
            <span>{quote.ophthalmology.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img
              src={odontologiaIcon}
              alt="odontologia"
              className="w-6 h-6 mr-2"
            />
            <span>Odontologia</span>
          </div>
          <div className="flex">
            <span>{quote.dentistry.toUpperCase()}</span>
          </div>
        </>
      </div>
    </div>
  );
}

export default function QuotationResults({
  formData,
  quotationResults,
  onBack,
}: QuotationResultsProps) {
  const [selectedPlans, setSelectedPlans] = useState<GetQuoteResponse[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<"relevance" | "maxPrice" | "minPrice">(
    "relevance"
  );
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [quotationDetails, setQuotationDetails] = useState<GetQuoteResponse>();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedPlans.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + selectedPlans.length) % selectedPlans.length
    );
  };

  const filteredResults = quotationResults
    .filter((quote) => {
      if (selectedProviders.length > 0) {
        if (!selectedProviders.includes(quote.nameprovider.toLowerCase())) {
          return false;
        }
      }

      if (selectedBenefits.length > 0) {
        if (
          selectedBenefits.some(
            (benefit) => quote[benefit as keyof GetQuoteResponse] === null
          )
        ) {
          return false;
        }
      }

      if (search) {
        if (
          !quote.clinics?.some((clinic) =>
            clinic.clinicName.toLowerCase().includes(search.toLowerCase())
          )
        ) {
          return false;
        }
      }
      if (maxPrice && maxPrice.toString().length >= 5) {
        if (parseInt(String(quote.price)) > maxPrice) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      if (orderBy === "relevance") {
        return 0;
      }
      if (orderBy === "maxPrice") {
        return a.price - b.price;
      }
      if (orderBy === "minPrice") {
        return b.price - a.price;
      }
      return 0;
    });

  return (
    <>
      {/* Filters */}
      <Filters
        age={formData.age}
        employType={
          employTypes.find((employ) => employ.value === formData.employ)!
        }
        zone={zoneTypes.find((zone) => zone.value === formData.zone)!}
        selectedProviders={selectedProviders}
        setSelectedProviders={setSelectedProviders}
        selectedBenefits={selectedBenefits}
        setSelectedBenefits={setSelectedBenefits}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        search={search}
        setSearch={setSearch}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        goBack={onBack}
      />

      {/* Selected plans */}
      <AnimatePresence>
        {selectedPlans.length > 0 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-purple-100 overflow-hidden"
          >
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: 0.1, duration: 0.3 }}
              className="py-4 px-4 md:px-20"
            >
              {/* Desktop View */}
              <div className="hidden md:grid md:grid-cols-3 md:gap-6">
                <AnimatePresence>
                  {selectedPlans.map((selectedPlan) => (
                    <motion.div
                      key={selectedPlan.planId}
                      layout
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{
                        opacity: { duration: 0.2 },
                        scale: { duration: 0.2 },
                        layout: { duration: 0.3 },
                      }}
                    >
                      <Card
                        quote={selectedPlan}
                        selectedPlans={selectedPlans}
                        setSelectedPlans={setSelectedPlans}
                        isInHeader
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>

              {/* Mobile Carousel */}
              <div className="md:hidden">
                <div className="relative">
                  <div className="relative overflow-hidden">
                    <motion.div
                      className="flex"
                      animate={{ x: `${-currentIndex * 100}%` }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      {selectedPlans.map((selectedPlan) => (
                        <div
                          key={selectedPlan.planId}
                          className="w-full flex-shrink-0 px-2"
                        >
                          <Card
                            quote={selectedPlan}
                            selectedPlans={selectedPlans}
                            setSelectedPlans={setSelectedPlans}
                            isInHeader
                          />
                        </div>
                      ))}
                    </motion.div>
                  </div>
                  {selectedPlans.length > 1 && (
                    <>
                      <button
                        onClick={prevSlide}
                        className="absolute left-0 top-2/2 transform -translate-y-2/2 p-2"
                      >
                        <BigArrowLeft className="w-6 h-6 text-primary" />
                      </button>
                      <button
                        onClick={nextSlide}
                        className="absolute right-0 top-2/2 transform -translate-y-2/2 p-2"
                      >
                        <BigArrowRight className="w-6 h-6 text-primary" />
                      </button>
                    </>
                  )}
                </div>
                <div className="mt-4 flex justify-center space-x-2">
                  {selectedPlans.map((_, index) => (
                    <div
                      key={index}
                      className={`w-2 h-2 rounded-full ${
                        index === currentIndex ? "bg-primary" : "bg-gray-300"
                      }`}
                    />
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Dialog
        open={isDetailsOpen}
        onClose={() => {
          setIsDetailsOpen(false);
          setQuotationDetails(undefined);
        }}
        aria-labelledby="Quotation Details"
        aria-describedby="Quotation Details"
        className="flex justify-center items-center"
        maxWidth="xl"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogContent>
          <div className="flex justify-end px-2 md:px-6">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setIsDetailsOpen(false);
                setQuotationDetails(undefined);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {quotationDetails && <QuoteDetails quote={quotationDetails} />}
        </DialogContent>
      </Dialog>

      {/* Quotation Results */}
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 xl:gap-x-20 py-4 px-6 xl:px-20">
        {filteredResults.map((quote, index) => (
          <Card
            key={quote.planId}
            quote={quote}
            order={index}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            isInHeader={false}
            setOpenDetails={() => {
              setIsDetailsOpen(true);
              setQuotationDetails(quote);
            }}
          />
        ))}
      </div>
    </>
  );
}
