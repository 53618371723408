
 import AbmViewContainer from "../components/AbmViewContainer/AbmViewContainer"
 
 const Abmview = () => {
   return (
     <div className='abm'>
      <AbmViewContainer/>
     </div>
   )
 }
 
 export default Abmview