import { appConfig } from ".";
import queryString from "query-string";
export type QueryParams = Record<
  string,
  string | number | string[] | number[] | undefined | boolean
>;

export interface ApiCallConfig extends RequestInit {
  params?: QueryParams;
  arrayParams?: QueryParams;
  isMultipart?: boolean;
}

export interface PaginationRequest {
  page: number;
  pageSize: number;
  offset: number;
}

export interface PaginationResponse {
  page: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
}

export interface ApiError {
  message?: string;
  response: Response;
  error: Error;
}

export interface ApiResponse<T> {
  data: T;
  error?: ApiError;
  pagination?: PaginationResponse;
}

export interface MutationOptions<T = {}, R = {}> {
  invalidate?: any[] | any[][];
  onMutate?: (variables: R) => Promise<unknown> | unknown;
  onSuccess?: (data?: T) => void;
  onError?: (error: ApiError, variables?: any) => void;
  onSettled?: (
    data: T,
    error: ApiError | null,
    variables: R,
    context: unknown
  ) => unknown;
}

export interface QueryOptions {
  enabled?: boolean;
  refetchInterval?: number;
  refetchOnWindowFocus?: boolean;
  keepPreviousData?: boolean;
}

export const getApiEndpoint = (
  endpoint: string,
  params: QueryParams = {},
  arrayParams: QueryParams = {}
): string => {
  const host = appConfig.API_HOST;
  const queryParams =
    Object.keys(params ?? {}).length > 0
      ? `?${queryString.stringify(params, { arrayFormat: "comma" })}`
      : "";
  const arrayQueryParams =
    Object.keys(arrayParams ?? {}).length > 0
      ? `${queryParams ? "&" : "?"}${queryString.stringify(arrayParams, {
          arrayFormat: "bracket",
        })}`
      : "";

  return `${host}/api/v1${endpoint}${queryParams}${arrayQueryParams}`;
};

export const callApi = async <T>(
  endpoint: string,
  { params, arrayParams, ...config }: ApiCallConfig
): Promise<ApiResponse<T[]>> => {
  return fetch(getApiEndpoint(endpoint, params, arrayParams), {
    ...config,
    mode: "cors",
    headers: {
      origin: "localhost",
      ...(config.headers ?? null),
      ...(config.body && !config.isMultipart
        ? {
            "Content-Type":
              (config.headers as any)?.["Content-Type"] ?? `application/json`,
          }
        : null),
    },
  }).then(async (response) => {
    if (response.ok) {
      const responseJson = await response.json();
      const flattenDeep = (arr: any[]): any[] => {
        return arr.reduce(
          (acc, val) =>
            Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
          []
        );
      };

      if (
        responseJson &&
        responseJson.data &&
        Array.isArray(responseJson.data)
      ) {
        const flattenedData = flattenDeep(responseJson.data);
        return { data: flattenedData as T[] };
      }

      if (Array.isArray(responseJson)) {
        const flattenedData = flattenDeep(responseJson);
        return { data: flattenedData as T[] };
      }

      throw new Error("Unexpected response structure");
    }
    const { error }: { error: Error } = await response.json().catch();

    if (response.status === 404) {
      window.location.replace("/");
    }

    throw { response, ...error };
  });
};
