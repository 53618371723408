import { useQuery } from "@tanstack/react-query";
import { ApiError, callApi } from "../config/api";

export interface GetQuotePayload {
  firstname: string;
  email: string;
  phone: number;
  age: number;
  employ: number;
  couple: number;
  coupleage: number;
  sons: number;
  zone: number;
}

export interface Clinic {
  clinicId: number;
  clinicName: string;
  clinicDesc: string;
  zoneId: number;
  zone: string;
  zoneDesc: string;
  locality: string | null;
  department: string | null;
  province: string | null;
}

export interface GetQuoteResponse {
  planId: number;
  providerId: number;
  employId: number;
  code: string;
  nameplan: string;
  tradename: string;
  baseplan: string;
  coverage: string;
  copayment: string;
  emergency_room: string;
  hospitalization: string;
  dentistry: string;
  orthodontics: string;
  office_visit: string;
  prosthesis: string;
  PMI: string;
  optics: string;
  home_visits: string;
  diagnostic_imaging: string;
  laboratory: string;
  physical_therapy: string;
  psychology: string;
  ophthalmology: string;
  outpatient_medications: string;
  cosmetic_surgery: string;
  bruxism_guard: string | null;
  rpg: string | null;
  telemedicine: string | null;
  documentpath: string | null;
  comments: string;
  created_at: string;
  updated_at: string;
  enabled: number;
  deact_date: string | null;
  id: number;
  nameprovider: string;
  clinics: Clinic[];
  price: number;
}

export function useGetQuoteQuery(payload: GetQuotePayload | null) {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user).token : null;

  return useQuery<any, ApiError>({
    queryKey: [payload],
    queryFn: () => {
      const formData = new FormData();
      if (payload) {
        Object.entries(payload).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }
      formData.append("Authorization", token);
      return callApi<GetQuoteResponse[]>("/quote", {
        method: "POST",
        body: formData,
        isMultipart: true,
      }).then(({ data }) => data);
    },
    enabled: !!payload,
  });
}
