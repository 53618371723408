import { motion } from "framer-motion";

const Spinner = () => {
  const circleSize = 260;
  const strokeWidth = 16;
  const radius = (circleSize - strokeWidth) / 2;
  const center = circleSize / 2;

  return (
    <motion.div initial="start" animate="end">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width={circleSize}
        height={circleSize}
      >
        <motion.path
          d="M95.3367 3.27082L36.4051 69.0227L17.5631 48C13.6544 43.6389 7.34027 43.6389 3.43154 48C-0.477181 52.3611 -0.477181 59.4059 3.43154 63.767L29.3895 92.7292C33.2982 97.0903 39.6123 97.0903 43.521 92.7292L109.568 19.0379C113.477 14.6768 113.477 7.63191 109.568 3.27082C105.66 -1.09027 99.2454 -1.09027 95.3367 3.27082Z"
          transform={`translate(${center - 56.5}, ${center - 48}) scale(0.9)`}
          variants={{
            start: { pathLength: 0, fill: "rgba(52, 232, 202, 0)" },
            end: { pathLength: 1, fill: "rgba(52, 232, 202, 1)" },
          }}
          initial="start"
          animate="end"
          transition={{
            pathLength: { duration: 2, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] },
          }}
        />
        <motion.circle
          cx={center}
          cy={center}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          stroke="#C0BFFF"
        />
        <motion.circle
          cx={center}
          cy={center}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          stroke="#34E8CA"
          strokeLinecap="round"
          variants={{
            start: { pathLength: 0 },
            end: { pathLength: 1 },
          }}
          transition={{ duration: 2, repeat: Infinity }}
        />
      </motion.svg>
    </motion.div>
  );
};

export default Spinner;
