import EscuelitaViewContainer from '../components/EscuelitaViewContainer/EscuelitaViewContainer'

const EscuelitaView = () => {

    return (
        <div className='escuelitaView'>
            <EscuelitaViewContainer/>
        </div>
    )
}
export default EscuelitaView