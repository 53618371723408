import { BorderBottom } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4744CC",
    },
    secondary: {
      main: "#34E8CA",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            padding: "4px 16px",
            borderRadius: 5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: "1px solid #9ca3af",
          },
          "& label": {
            fontWeight: 600,
            fontSize: 15,
          },
          "& label.Mui-focused": {
            fontSize: 15,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSelect-select": {
            padding: "4px 16px",
          },
          "& .MuiSelect-icon": {
            fontSize: 40,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 32,
          padding: 0,
        },

        switchBase: {
          padding: 6,
          "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: "#4744cc",
              opacity: 1,
            },
            "& .MuiSwitch-thumb": {
              backgroundColor: "#ffffff",
            },
          },
        },
        thumb: {
          backgroundColor: "#AEAEAE",
          width: 20,
          height: 20,
          boxShadow: "none",
          border: "4px solid #fff",
        },
        track: {
          borderRadius: 40,
          backgroundColor: "#AEAEAE",
          opacity: 1,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-outlined": {
            borderColor: "#4744CC",
            color: "#4744CC",
          },
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "#4744CC",
          },
        },
      },
    },
  },
});

export default theme;
